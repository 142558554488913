import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { transformPostSchema } from '../util';
import HeaderMeta from '../components/HeaderMeta';

import {
  HeadingBox,
  OctopusBlueFrame,
  BlogCategoryHeading,
  BlogPostList,
  SectionGroup,
} from "@minware/ui-minware";

export const BlogCategoryTemplate = ({
  content,
  postList,
  octopus,
  slug,
}) => {
  octopus = octopus || {};
  postList = postList || [];
  const posts = postList.map(({node}) => transformPostSchema(node));
  const canonicalUrl = slug[slug.length - 1] === '/' ? slug.substring(0, slug.length - 1) : slug;

  const headerWithCanon = {
    ...content?.header,
    canonicalUrl,
  };

  return (
    <Layout desktopBgImageName="none">
      <HeaderMeta header={headerWithCanon}/>

      <BlogCategoryHeading
        heading={content.displayName}
        subHeadline={content.description}
      />

      <SectionGroup>
        <HeadingBox level="h1" tagLevel="h2" evenMargin={true}>
          Latest Articles
        </HeadingBox>

        <BlogPostList posts={posts}/>
      </SectionGroup>
    </Layout>
  )
};

const BlogCategoryPage = (props) => {
  const { data } = props;
  const { index, main, all } = data;

  const content = main.frontmatter;
  const postList = all.edges;
  const octopus = index.frontmatter.octopus;

  const header = {
    title: `${content.displayName} Archives | minware`,
    description: `Read about the latest trends in ${content.displayName}.`,
  }
  content.header = content.header || {};
  content.header.title = content.header.title || header.title
  content.header.description = content.header.description || header.description;

  return (
    <BlogCategoryTemplate
      content={content}
      postList={postList}
      octopus={octopus}
      slug={main.fields.slug}
    />
  );
}

export default BlogCategoryPage

export const pageQuery = graphql`
  query BlogCategoryPage($id: String, $categoryName: String) {
    index: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      ...OctopusFragment
    }

    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      fields {
        slug
      }
      frontmatter {
        categoryName
        displayName
        description
      }
    }

    all: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          categories: {
            elemMatch: {
              frontmatter: {
                categoryName: {
                  eq: $categoryName
                }
              }
            }
          },
          templateKey: {
            eq: "blog-post"
          }
        }
      }
    ) {
      totalCount
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
  }
`
